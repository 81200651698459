<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="interfaces"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        sort-by="rack"
        :hide-default-footer="interfaces.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            v-bind:label="$t('search')"
            single-line
            :clearable="!isMobile"
          />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-traffic-stats-details :interface-id="item.id" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import RzoTrafficStatsDetails from "@/components/services/rzo/RzoTrafficStatsDetails";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoTrafficStats",
  components: {
    RzoTrafficStatsDetails,
  },
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  mixins: [isMobile],
  data: () => ({
    interfaces: [],
    loading: false,
    search: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("rack"),
          value: "rack",
          filterable: true,
        },
        {
          text: this.$i18n.t("switch"),
          value: "switch",
        },
        {
          text: this.$i18n.t("description"),
          value: "descr",
        },
        {
          text: this.$i18n.t("alias"),
          value: "alias",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function() {
      this.interfaces = [];
      this.getInterfaces();
    },
  },
  methods: {
    getInterfaces: function() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/rzo/traffic/interfaces", {
          params: {
            product_id: this.productId,
          },
        })
        .then((response) => {
          that.interfaces = response.data;
        })
        .catch((error) => {
          if (error.status === 400)
            console.log("ignore user error: ", error.message);
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function() {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getInterfaces();
  },
};
</script>

<i18n>
{
  "en": {
    "search": "Search",
    "show details": "show details",
    "rack": "Rack",
    "switch": "Switch",
    "description": "Description",
    "alias": "Alias"
  }, 
  "de": {
    "search": "Suche",
    "show details": "zeige Details",
    "rack": "Rack",
    "switch": "Switch",
    "description": "Beschreibung",
    "alias": "Alias"
  }
}
</i18n>
