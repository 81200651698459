<template>
  <v-card class="mt-4 mb-4" outlined>
    <v-progress-linear :active="loading" indeterminate />
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer></v-spacer>
      <date-picker
        v-model="dates"
        :allowedDates="dateAllowed"
        icon
        range
        sort-range
        left
        bottom
        rounded
      />
      <v-menu left bottom rounded>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-vector-arrange-above</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group v-model="interval" color="primary">
            <v-list-item
              v-for="(choice, i) in intervalChoices"
              :key="i"
              :disabled="i == interval"
            >
              <v-list-item-title>
                {{ $t(choice.text) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <apexchart
        v-if="options != null && series != null"
        :options="options"
        :series="series"
        height="360px"
        class="flex-grow-1"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from "@/components/basics/DatePicker";
import VueApexCharts from "vue-apexcharts";

var moment = require("moment");

export default {
  name: "RzoTrafficStatsDetails",
  components: {
    DatePicker,
    apexchart: VueApexCharts,
  },
  props: {
    interfaceId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    interval: 1,
    intervalChoices: [
      { seconds: 300, text: "5minutes" },
      { seconds: 3600, text: "hourly" },
      { seconds: 86400, text: "daily" },
    ],
    data: null,
    series: null,
    options: null,
    outP95: null,
    inP95: null,
    dates: [
      moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ],
    loading: false,
    baseOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        stacked: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      annotations: {},
    },
  }),
  watch: {
    "$vuetify.theme.dark": {
      immediate: true,
      handler(value) {
        this.baseOptions = {
          ...this.baseOptions,
          tooltip: {
            theme: value ? "dark" : "light",
          },
          noData: {
            text: this.$i18n.t("no data"),
          },
          yaxis: {
            forceNiceScale: true,
            title: {
              text: "MB/s",
            },
          },
        };
      },
    },
    interfaceId: function () {
      this.getStats();
    },
    dates(value) {
      if (value && value.length == 2) {
        this.getData();
      }
    },
    interval(value) {
      this.getData();
    },
  },
  methods: {
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(date).isBefore(today)
      );
    },
    getData() {
      if (!this.loading) {
        var that = this;
        this.loading = true;
        this.$http
          .get("services/rzo/traffic/data", {
            params: {
              interface: this.interfaceId,
              interval: this.intervalChoices[this.interval].seconds,
              time_after:
                this.dates.length > 0 ? this.dates[0] + " 00:00:00" : null,
              time_before:
                this.dates.length > 1 ? this.dates[1] + " 23:59:59" : null,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              that.data = response.data[0];
              that.outP95 = (
                ((that.data.out_p95 || 0) * 8) /
                (that.data.interval * 1000000)
              ).toFixed(3);
              that.inP95 = (
                ((that.data.in_p95 || 0) * -8) /
                (that.data.interval * 1000000)
              ).toFixed(3);
              that.series = [];
              that.options = {
                ...that.baseOptions,
                annotations: {
                  position: "front",
                  yaxis: [
                    {
                      y: that.outP95,
                      borderColor: "#C4C422",
                      strokeDashArray: 0,
                      label: {
                        text: "out P95 (" + that.outP95 + ")",
                        borderColor: "#F9F90A",
                        style: {
                          color: "#000",
                          background: "#F9F90A",
                        },
                      },
                    },
                    {
                      y: that.inP95,
                      borderColor: "#C4C422",
                      strokeDashArray: 0,
                      label: {
                        text: "in P95 (" + that.inP95 + ")",
                        borderColor: "#F9F90A",
                        style: {
                          color: "#000",
                          background: "#F9F90A",
                        },
                      },
                    },
                  ],
                },
              };

              // out bandwidth
              that.series.push({
                name: that.$i18n.t("out bandwidth"),
                data: that.data.data.map(function (e) {
                  return {
                    x: e[0],
                    y: ((e[1] * 8) / (that.data.interval * 1000000)).toFixed(3),
                  };
                }),
              });

              // out bandwidth min
              if (that.data.interval != 300)
                that.series.push({
                  name: that.$i18n.t("out bandwidth min"),
                  data: that.data.data.map(function (e) {
                    return {
                      x: e[0],
                      y: ((e[2] * 8) / (that.data.interval * 1000000)).toFixed(
                        3
                      ),
                    };
                  }),
                });

              // out bandwidth max
              if (that.data.interval != 300)
                that.series.push({
                  name: that.$i18n.t("out bandwidth max"),
                  data: that.data.data.map(function (e) {
                    return {
                      x: e[0],
                      y: ((e[3] * 8) / (that.data.interval * 1000000)).toFixed(
                        3
                      ),
                    };
                  }),
                });

              // in bandwidth
              that.series.push({
                name: that.$i18n.t("in bandwidth"),
                data: that.data.data.map(function (e) {
                  return {
                    x: e[0],
                    y: ((e[4] * -8) / (that.data.interval * 1000000)).toFixed(
                      3
                    ),
                  };
                }),
              });

              // in bandwidth min
              if (that.data.interval != 300)
                that.series.push({
                  name: that.$i18n.t("in bandwidth min"),
                  data: that.data.data.map(function (e) {
                    return {
                      x: e[0],
                      y: ((e[5] * -8) / (that.data.interval * 1000000)).toFixed(
                        3
                      ),
                    };
                  }),
                });

              // in bandwidth max
              if (that.data.interval != 300)
                that.series.push({
                  name: that.$i18n.t("in bandwidth max"),
                  data: that.data.data.map(function (e) {
                    return {
                      x: e[0],
                      y: ((e[6] * -8) / (that.data.interval * 1000000)).toFixed(
                        3
                      ),
                    };
                  }),
                });
            } else {
              that.series = null;
              that.options = null;
              that.outP95 = null;
              that.inP95 = null;
            }
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<i18n>
{
  "en": {
   "reload": "reload",
   "select interval": "select interval",
   "no data": "no data",
   "5minutes": "5 minutes samples",
   "hourly": "60 minutes aggregates",
   "daily": "24 hours aggregates",
   "out bandwidth": "in bandwidth",
   "out bandwidth min": "in bandwidth min",
   "out bandwidth max": "in bandwidth max",
   "in bandwidth": "in bandwidth",
   "in bandwidth min": "in bandwidth min",
   "in bandwidth max": "in bandwidth max"
  },
  "de": {
   "reload": "aktualisieren",
   "select interval": "Interval auswählen",
   "no data": "no data",
   "5minutes": "5 Minutes Samples",
   "hourly": "60 Minuten Aggregate",
   "daily": "24 Stunden Aggregate",
  "out bandwidth": "in Bandbreite",
   "out bandwidth min": "in Bandbreite Min",
   "out bandwidth max": "in Bandbreite Max",
   "in bandwidth": "in Bandbreite",
   "in bandwidth min": "in Bandbreite Min",
   "in bandwidth max": "in Bandbreite Max"
  }
}
</i18n>
